/*------------- #FOOTER --------------*/


.footer {
  position: relative;
  color: var(--footer-text-color);

  &.footer--dark {
	background-color: #1b1c1d;
  }

  a:not(.back-to-top):not(.crumina-button) {
	color: var(--footer-link-color);
	fill: var(--footer-link-color);

	&:hover {
	  color: var(--primary-accent-color);
	  fill: var(--primary-accent-color);
	}
  }

  .widget-title {
	color: var(--white-color);
	margin-bottom: 20px;
  }

  &.footer--with-decoration {
	&:before {
	  content: '';
	  display: block;
	  width: 100%;
	  height: 4px;
	  background-repeat: repeat-x;
	  background-size: contain;
	  position: absolute;
	  bottom: 0;
	  left: 0;
	}
  }

  &.js-fixed-footer {
	width: 100%;
	bottom: 0;
	left: 0;
	right: 0;
	position: fixed;
	z-index: 1;
	-webkit-transition-property: all;
	-moz-transition-property: all;
	transition-property: all;
	-webkit-transition-duration: 0.4s;
	-moz-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-timing-function: cubic-bezier(0, 0, 0, 1);
	-moz-transition-timing-function: cubic-bezier(0, 0, 0, 1);
	transition-timing-function: cubic-bezier(0, 0, 0, 1);

	@media (max-width: 768px) {
	  position: relative;
	}
  }
}

.footer.footer--with-decoration:before {
  background-image: url("../assets/img/theme-content/backgrounds/header-footer-gradient-bg.png");
}

.footer-content {
	padding: 120px 0 60px;
}

.sub-footer {
  padding: 40px 0;

  a:hover {
	color: var(--primary-accent-color);
  }
}

.copyright {
  font-size: 14px;
  font-weight: 500;

}

.footer-subscribe-panel {
  padding: 60px;
  border-top: 1px solid #323334;
}

.footer-subscribe-panel-title {
  margin-bottom: 0;
}

.block-footer-height {
  position: relative;
  width: 100%;

  @media (max-width: 768px) {
	display: none;
  }
}

/*------------- Responsive Mode --------------*/


@media (max-width: 768px) {
  .footer {
	text-align: center;
  }

  .footer-subscribe-panel {
	padding: 30px;
  }

  .footer-content {
	padding: 40px 0;
  }

  .sub-footer {
	padding: 20px 0;
  }

  .footer .widget-title {
	margin-bottom: 20px;
  }
}